/* Full page styling */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.app {

    background-color: #b5d6df;
    /* background-color: #00bff1; */
    /* Dark background */
    min-height: 100vh;
    /* color: #fff; */
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /* background-color: #4682B4; */
    background-color: white;
    /* color: white */
    color: black;
}



.logo {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
}


.header-buttons {
    display: flex;
}

.header-btn {
    background-color: #4682B4;
    color: white;
    border: none;
    padding: 5px 20px;
    /* margin-left: 10px; */
    cursor: pointer;
    border-radius: 22px;
    font-size: 16px;
}

.header-btn:hover {
    background-color: #deedf1;
    color: black;
    border: 1px solid #1e1e1e;
}

/* Main content styling */
.main-content {
    margin-top: 50px;
    text-align: center;
    padding: 60px 10px;
    flex-grow: 1;
    height: 100vh;
    /* Set the height to the full viewport */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.main-text {
    font-size: 24px;
    margin-bottom: 6%;
    font-weight: bold;
}

h1 {
    font-size: 48px;
}


/* Styling the container */
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Adjust spacing between divs */
    flex-wrap: wrap;
    /* Allow the divs to wrap if they overflow */
    gap: 40px;
    /* Adjust gap between cards */
    /* padding: 2%; */
    /* padding-left: 6%; */
    height: 100%;
    /* overflow: auto; */
}

/* Styling each card to make them uniform */
.card {
    background-color: white;
    /* Dark background as per screenshot */
    color: black;
    /* Text color */
    border-radius: 5px;
    /* Rounded corners */
    padding: 20px;
    width: 330px;
    /* Adjust width to fit your design */
    height: 290px;
    /* Adjust height to ensure uniformity */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Optional shadow for depth */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 2px solid rgb(219, 219, 219);
}

.card h3 {
    font-weight: 600;
    font-size: 20px;
}

.sub-heading {
    margin-top: 10px;
    font-weight: 600;
    font-size: 12px;
    color: #BA5B38;
}

.paragraph-text {
    margin-top: 10px;
    font-size: 12px;
    color: #494848;
}



.card-image {
    height: 50px;
    width: 50px;
}

.know-more-button {
    margin-top: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #4682B4;
    /* Primary color */
    color: #fff;
    /* White text */
    padding: 5px 20px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.know-more-button:hover {
    background-color: #4682B4;
    /* Darker shade for hover */
}

.arrow {
    margin-left: 8px;
    /* Space between text and arrow */
    font-size: 18px;
}



/* Optional styling for hover effect */
.card:hover {
    transform: scale(1.05);
    /* Slightly enlarges card on hover */
    transition: transform 0.2s ease-in-out;
}


/* Modal styles (use from previous example) */

.upload-contents {
    width: 85%;
    padding-right: 10%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background-color: #1e1e1e;
    /* Main content area color */
}

@keyframes highlight {
    0% {
        color: black;
    }

    50% {
        color: red;
    }

    100% {
        color: rgba(115, 188, 100, 0.714);
    }
}

.blink {
    animation: highlight 1s infinite;
}

.logo-img {
    display: flex;
    width: 60px;
    margin-right: 10px;
    margin-left: 10px;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-heading {
    text-align: center;
    font-size: 14px;
    /* Optional: Adjust the font size */
    color: #333;
    /* Optional: Adjust the text color */
}
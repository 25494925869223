@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


.chat-div {
    position: absolute;
    right: 0;
    /* display: inline-flex; */
    /* flex-direction: column; */
    bottom: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 25%;
    z-index: 500;
}

.chat-box {
    width: 100%;
    height: calc(100vh - 60px);
    height: calc(var(--vh, 1vh) * 100 - 60px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
    scroll-behavior: smooth;
}

/* .training-btn,
.qa-btn {
    margin-top: 4%;
    margin-right: 0%;
    border: none;
    cursor: pointer;
    color: white;
    width: 44%;
    height: 100%;
    font-size: larger;
} */

/* .training-btn {
    background-color: #4CAF50;
} */

/* .qa-btn {
    background-color: #f44336;
} */

/* Add hover effect */
/* .training-btn:hover,
.qa-btn:hover {
    opacity: 0.8;
} */

/* @media screen and (max-width: 1100px) {
    .chat-div {
        width: 400px;
    }
} */

.chat-box::-webkit-scrollbar {
    width: 7px;
}

.chat-box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.chat-box::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.msg-box {
    border: 1px solid rgba(255, 255, 255, 0.7);
    /* height: 55px; */
    display: flex;
    border-radius: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(50, 49, 49, 0.678);
    box-sizing: border-box;
    max-width: 600px;
    width: 100%;
}

.text-area {
    flex-grow: 1;
    max-height: 120px;
    overflow-y: auto;
    resize: none;
    width: 100%;
    padding: 4px;
    border-radius: 16px;
    box-sizing: border-box;
    background-color: rgba(50, 49, 49, 0.678);
    color: white;
    font-size: medium;
}

.text-area::placeholder {
    color: white;
    opacity: 1;
}

#mic {
    height: 40px;
    width: 40px;
    margin-left: 10px;
    border-radius: 18px;
    transition: all ease 0.3s;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    pointer-events: none;
    user-select: none;
}

#mic:active {
    transform: scale(1.1);
    background: linear-gradient(-45deg, #35C3F3 0%, #8b9fe8 20%, #e681d8 39%, #ffa9a4 76%, #FED2CE 100%);
}

.msg-box input {
    width: calc(75%);
    background-color: transparent;
    font-size: 18px;
    color: rgb(236, 234, 234);
}

.msg-box input::placeholder {
    color: rgb(209, 209, 209);
}

#send {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 18px;

}

#send:hover {
    background-color: #35a4f3;
}

.msgbtn {
    background: transparent;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.msgbtn img {
    width: 22px;
    height: 22px;
}

.msgbtn:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.chat-box p {
    width: fit-content;
    font-size: 19px;
    padding: 5px 6px;
    color: white;
    border-radius: 30px;
    background-color: rgb(31, 31, 31);
    margin-block: 5px;
    max-width: 350px;
    margin-left: 5px;
    opacity: 10;
}

.chat-box p.bot {
    margin-block: 5px;
    max-width: 93%;
    margin-left: 5px;
}

.chat-box p.me {
    margin-left: auto;
    margin-right: 10px;
    background-color: #35a4f3;
}

.chat-box p .time {
    font-size: 10px;
    color: rgb(143, 143, 143);
    text-align: end;
}

.Toastify__progress-bar {
    height: 4px;
}

/* @media screen and (max-width: 750px) {
    .chat-div {
        width: auto;
    }
} */

@media screen and (max-width: 500px) {

    /* .chat-div {
        width: 100%;
        height: 350px;
        bottom: 0;
    } */

    .chat-box {
        height: calc(100vh - 70px);
        height: calc(var(--vh, 1vh) * 100 - 70px);
    }

    .msg-box {
        margin-bottom: 10px;
        margin-left: 7px;
        margin-right: 7px;
    }

    .chat-box p {
        font-size: 17px;
    }

    .msg-box input {
        font-size: 17px;
    }

    /* .train-msg-box {
        margin-bottom: 10px;
        margin-left: 7px;
        margin-right: 7px;
    } */
    /* 
    .train-msg-box input {
        font-size: 17px;
    } */
}

.about {
    position: absolute;
    left: 15px;
    top: 15px;
    display: flex;
    z-index: 3;
    padding: 7px;
    background-color: #222;
    border-radius: 4px;
    cursor: pointer;
}

.about img {
    width: 22px;
    height: 22px;
}

.modal {
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 90%;
    max-width: 400px;
    padding: 10px;
    text-align: center;
    background: rgba(29, 28, 28, 0.7);
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    transition: all 1s ease-out;
    animation: ease-out 1s;
}

.modal h1 {
    margin-top: 20px;
}

.modal a {
    color: #35a4f3;
    text-decoration: none;
}

.full {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 200px;
    background-color: #333;
    color: #fff;
    padding: 4px;
    overflow-y: auto;
    /* Enable vertical scrolling */
    height: 100%;
    transition: width 0.3s;
}

.sidebar.collapsed {
    width: 0;
    padding: 0;
    /* Remove padding when collapsed */
    overflow: hidden;
    /* Hide overflow content */
}

.logout-container {
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
    margin-right: 1px;
    /* Optional, adjust for spacing */
}

.logout-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    padding: 2px;
}

.logout-icon {
    font-size: 30px;
    /* Icon size */
    color: #333;
}

.logout-text {
    margin-left: 8px;
    /* Space between the icon and the text */
    opacity: 0;
    transition: opacity 0.3s ease;
}

.logout-button:hover .logout-text {
    opacity: 1;
}

.logout-button:hover .logout-icon {
    color: #ff0000;
    /* Optional, change color on hover */
}


.main-content {
    flex: 1;
    padding: 4px;
    transition: margin-left 0.3s, width 0.3s;

}

.main-content.expanded {
    margin-left: 0;
    /* No margin when the sidebar is hidden */
}

.toggle-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    margin-bottom: 2px;
    /* Space between the toggle button and the rest of the content */
}

.chat-item {
    position: relative;
    /* padding-left: 10px; */
}

.chat-message-container {
    display: flex;
    justify-content: space-between;
    /* Aligns message to the left and dots to the right */
    align-items: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    /* Ensures it takes the full width of the container */
    /* background-color: dimgray; */
}

.chat-message-container:hover {
    background-color: dimgray;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
}

.chat-heading {
    flex-grow: 1;
    /* Allow the message to take available space */
    margin-right: 10px;
    /* Adds space between the message and the three dots */
    cursor: pointer;
    white-space: nowrap;
    /* Prevent the text from wrapping into a new line */
    overflow: hidden;
    /* Hide the overflowing text */
    /* text-overflow: ellipsis; Add "..." at the end of the truncated text */
    font-size: 16px;
}

.copy-icon,
.audio-icon {
    position: relative;
    display: inline-block;
    color: white;
    margin-left: 5px;
}

.tooltip-text {
    visibility: hidden;
    width: 80px;
    background-color: rgb(223, 55, 55);
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 1px;
    position: absolute;
    z-index: 1000;
    top: -35px;
    right: 0;
    left: 3px;
    opacity: 1;
    transition: opacity 0.3s;
    font-size: 15px;
}

/* Show tooltip when hovering over the icon */
.copy-icon:hover .tooltip-text,
.audio-icon:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.dots-icon {
    cursor: pointer;
    visibility: hidden;
    font-size: 19px;
    margin-left: 10px;
    /* Adjust this if needed to position the dots better */
}

.chat-message-container:hover .dots-icon {
    visibility: visible;
    /* Show the dots when hovering */
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    /* background-color: rgb(38, 77, 203); */
    border: none;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    padding: 2px;
    z-index: 10;
    /* border-radius: 18px; */
}

.dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu li {
    padding: 4px 6px;
    cursor: pointer;
}

/* .dropdown-menu li:hover {
    background-color: #191818;
    border: none;
   
} */


.upload-contents {
    width: 56%;
    padding-right: 10%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background-color: #1e1e1e;
    /* Main content area color */
}


/* .topic-dropdown label,
.topic-buttons label {
    margin-top: 0;
    font-weight: bold;
    color: rgba(81, 255, 0, 0.533);
    font-size: 20px;
} */
.topic-dropdown label {
    font-size: 16px;
    margin-right: 5px;
    font-weight: bold;
}


.topic-buttons {
    margin-bottom: 3px;
    position: relative
}

.topic-button {
    margin: 3px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.topic-button.active {
    background-color: #007bff;
    color: #fff;
}

.train-div {
    position: absolute;
    right: 0;
    /* display: inline-flex;
    flex-direction: column; */
    bottom: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 36%;
    z-index: 100;
    overflow: auto;
}

.topic-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 535px;
}

.topic-details p {
    width: fit-content;
    font-size: 16px;
    padding: 5px 6px;
    color: black;
    border-radius: 20px;
    background-color: rgb(159, 167, 193);
    margin-block: 5px;
    max-width: 430px;
    max-height: fit-content;
    margin-left: 5px;
    /* opacity: 10; */
    z-index: 500;
}

.desc {
    background-color: rgb(210, 224, 226);
    color: black;
    width: fit-content;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    padding: 5px 6px;
    border-radius: 20px;
    margin-block: 5px;
    max-width: 450px;
    margin-left: 5px;
    /* opacity: 10; */
    z-index: 500;
}

.steps {
    margin-left: 15%;
    margin-right: 5%;
    margin-bottom: 3%;
    color: white;
    font-size: 17px;
    z-index: 500;
    border-radius: 10px;
    background-color: rgb(159, 167, 193);
    display: flex;
    flex-direction: column;
}

.media-image {
    padding: 0px 20px 20px 20px;
}

.active-step {
    font-weight: bold;
}

/* Overlay background */
.popup-overlay {
    position: fixed;
    top: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1000;
}

/* Popup content */
.popup-content {
    background-color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 340px;
    /* width: 90%; */
    text-align: center;
    color: #333;
}

.popup-step {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.popup-question {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

/* Popup buttons */
.popup-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.popup-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

/* Confirm and Cancel button styles */
.popup-button.confirm {
    background-color: #4CAF50;
    color: white;
}

.popup-button.confirm:hover {
    background-color: #43a047;
}

.popup-button.cancel {
    background-color: #f44336;
    color: white;
}

.popup-button.cancel:hover {
    background-color: #e53935;
}

.navigation-buttons {
    display: flex;
    /* justify-content: flex-end; */
    /* align-items: center; */
    /* gap: 10px; */
    margin-top: 10px;
}

.nav-icon {
    font-size: 22px;
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 100;
}

.nav-icon:hover {
    color: #2d21d7;
}

.previous {
    margin-left: 5%;
    margin-right: auto;
    color: red
}

.next {
    margin-left: auto;
    color: green;
    font-size: 30px;
    font-weight: 500;
    margin-right: 5%;
}

.popup {
    position: fixed;
    top: 7%;
    left: 35%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-video {
    background-color: #ffffff;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 90%;
    text-align: center;
    color: #333;
}

.close-video {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 30px;
    color: red;
    cursor: pointer;
}

.close-video:hover {
    color: #e681d8;
}

.quiz-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.quiz-popup-content {
    background-color: rgb(199, 225, 167);
    padding: 3px;
    border-radius: 10px;
    /* max-width: 500px; */
    width: 50%;
    height: 60%;
    text-align: center;
}

.quiz-popup-content_room {
    background-color: rgb(199, 225, 167);
    padding: 3px;
    border-radius: 10px;
    left: 10px;
    width: 90%;
    height: 60%;
    text-align: center;
}

.shiny-text {
    font-weight: bold;
    background: linear-gradient(90deg, #ffcc33, #ffffff, #ffcc33);
    background-clip: text;
    color: transparent;
    position: relative;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.6);
    animation: shine 3s linear infinite;
}

@keyframes shine {
    0% {
        background-position: 0%;
    }

    100% {
        background-position: 200%;
    }
}

.quiz-question {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.quiz-options button {
    display: inline-block;
    width: fit-content;
    margin: 10px 5px;
    padding: 15px;
    /* background-color: white; */
    color: #002984;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid gray;
    transition: transform 0.2s, box-shadow 0.2s;
}

.quiz-options button:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.quiz-navigation-buttons button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #002984;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
}

.confirmation-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.confirmation-popup-content {
    background: #fff;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    text-align: center;
}

.confirmation-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}

.confirm-button,
.cancel-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirm-button {
    background-color: #28a745;
    color: white;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
}

.correct-option {
    background-color: #4caf50;
    /* Green */
    color: white;
}

.incorrect-option {
    background-color: #f44336;
    /* Red */
    color: white;
}

.quiz-feedback {
    margin-top: 15px;
    font-size: 1em;
    color: #4774e3;
}

.close-answer {
    display: flex;
    justify-content: space-between;
    /* Space between the elements */
    align-items: center;
    /* Align items vertically in the center */
}

.quiz-question-title {
    margin: 0;
    text-align: center;
    /* This will center the text inside the h2 element */
    flex-grow: 1;
    font-weight: bold;
    /* Ensure that the title takes up available space, pushing the close button to the right */
}

.close-popup-icon {
    background: none;
    border: none;
    font-size: 25px;
    cursor: pointer;
    font-weight: bold;
    color: red;
}

.chat-open-btn {
    position: fixed;
    bottom: 45px;
    left: 9px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 140px;
    height: 70px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 500;
}


.chat-popup {
    position: fixed;
    bottom: 3px;
    left: 10%;
    width: 300px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.chat-body {
    display: flex;
    flex-direction: column;
    max-height: 555px;
    overflow: auto;
}

.train-msg-box {
    display: flex;
    align-items: center;
}

.msgbtn {
    background: none;
    border: none;
    cursor: pointer;
}

.text-area {
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* padding: 10px; */
    /* margin: 0 10px; */
    resize: none;
}

.text-area:focus {
    outline: none;
    border-color: #007bff;
}


.chat-messages {
    max-height: 600px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    border-radius: 16px;
    align-items: center;
    left: 14%;
    bottom: 10%;
    width: 25%
}

.chat-messages_trainingSite {
    max-height: 500px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    /* border: 1px solid #ccc; */
    /* padding: 5px; */
    /* background-color: #f9f9f9; */
}

.chat-train-message {

    word-wrap: break-word;
    padding: 0px 8px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.chat-train-message_site {
    max-width: 85%;
    word-wrap: break-word;
    padding: 0px 8px;
    border-radius: 10px;
    margin-bottom: 5px;

}

.ai-message {
    background-color: rgb(143, 143, 143);
    color: #000;
    align-self: flex-start;
    margin-left: 10px;
    margin-right: 5%;
}

.user-messages {
    margin-left: 60px;
    margin-right: 10px;
    background-color: #007bff;
    border-radius: 10px;
    color: #fff;
    align-self: flex-end;
}

.ai-message_training {
    color: #000;
    align-self: flex-start;
    margin-left: 10px;
}

.user-messages_training {
    margin-left: 60px;
    margin-right: 10px;
    /* background-color: #007bff; */
    border-radius: 10px;
    color: #fff;
    align-self: flex-end;
}

.dropdown {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.dropdown:hover {
    border-color: #007bff;

}

.dropdown:focus {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    border-color: #007bff;

}

.dropdown option[value=""] {
    color: gray;
    font-style: italic;
}

.side_auto {
    overflow: auto;
    z-index: -1;
    height: 60vh;
}

.chat-box-fixed {
    border: 1px solid rgba(255, 255, 255, 0.7);
    position: fixed;
    display: flex;
    border-radius: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(50, 49, 49, 0.678);
    box-sizing: border-box;
    left: 14%;
    bottom: 5px;
    /* z-index: 1000; */
    width: 25%;
}

.chat-box-fixed_trainingSite {
    border: 1px solid rgba(255, 255, 255, 0.7);
    position: fixed;
    display: flex;
    border-radius: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(50, 49, 49, 0.678);
    box-sizing: border-box;
    /* max-width: 500px; */
    bottom: 5px;
    right: 5px;
    /* z-index: 1000; */
    width: 25%;
}

/* for  train alalysis */

/* .training-analysis-table {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 1 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    background-color: #e58686;
    z-index: 1000;
}

.training-analysis-table table {
    width: 100%;
    border-collapse: collapse;
}

.training-analysis-table th,
.training-analysis-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.training-analysis-table th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
}

.training-analysis-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.training-analysis-table tr:hover {
    background-color: #f1f1f1;
}

.training-analysis-table .no-data {
    text-align: center;
    color: #888;
    font-style: italic;
} */

@media (max-width: 1200px) {
    .trainAnalysisView {
        padding-left: 5%;
        max-height: 50%;
    }

    .showModel {
        width: 100%;
        margin: 0 auto;
        max-height: 50%;
    }
}

.trainAnalysisView {
    z-index: 10;
    /* Higher than default but not excessive */
    background-color: #f5f5f5;
    overflow-y: auto;
    width: 100%;
}

.showModel {
    z-index: 20;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow-y: auto;
    width: 100%;
}

.buttonStyles {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 5px;
    background-color: #40636c;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px,
}

.backButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #007BFF;
    /* background-color: #007BFF; */
    /* color: white; */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px,
}

.closeButton {
    color: #FF6347;
    border: none;
    cursor: pointer;
    font-size: 18px;
    background: none;
}

.closeHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
    z-index: 1000;
}

.statusCheck {
    color: white;
    /* width: 3%;
    border-radius: 20px;
    margin-bottom: 5px; */

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.overlay {
    position: fixed;
    top: 5%;
    left: 13%;
    width: 750px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: left;
    align-items: left;
    z-index: 1000;
}

.modalAnalysis {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
    overflow-y: auto;
    max-height: 90vh;
}

/* end trainig analysis */
.showTrainingForm {
    /* margin-left: 10%; */
    z-index: 1000;
    padding-top: 0;
    margin-top: 0;
    width: 200px;
}

.showTrainRoom {
    overflow-y: auto;
    max-height: 150px;
    scrollbar-width: none;
    -ms-overflow-style: none;

}

.showInviteUser {
    max-width: 210px;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.upload-label {
    font-size: 0.875rem;
    /* Small text */
    margin-bottom: 0.5rem;
    /* Space below the label */
}

.file-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.375rem;
}

.active_users {
    padding-left: 20px;
    font: bold;
}

.messagelive {
    padding-left: 20px;
    color: #007BFF;
    height: 500px !important;
    overflow-y: auto;
}

.AI {
    color: red;
    font: bold;
    font-size: large;
}


.user-suggestions {
    position: absolute;
    /* top: 100%; */
    /* Positions the dropdown just below the textarea */
    left: 25%;
    background-color: #ffffff;
    border: 1px solid #ddd;
    /* width: 100%; */
    max-height: 150px;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.user-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-item:hover {
    background-color: #f0f0f0;
}

.user-name {
    font-weight: bold;
    color: #333;
}

.user-type {
    font-size: 0.9rem;
    color: #888;
}

.permission {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: green;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 22px;
}


.container_permission {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000;
}


.message_permission {
    font-size: 16px;
    margin-bottom: 10px;
}

.actions_permission {
    display: flex;
    justify-content: space-between;
}

.confirmButton {
    display: flex;
    align-items: center;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer
}

.rejectButton {
    display: flex;
    align-items: center;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
}

.trainingStart {
    color: black;
    background-color: rgb(137, 237, 137);
    border-radius: 10px;
    font-size: 20px;
    z-index: 1000;
    /* padding: 5px 20px; */
    max-width: fit-content;
    font-weight: bold;
    margin-left: 2px;
    margin-top: 1%;
    margin-right: 4px;
}

.meetingContainer {
    display: flex;
}

.showMeetingName {
    color: white;
    max-width: fit-content;
    background-color: #b63d3d;
    border-radius: 15px;
    padding: 5px;
    left: 0px;
    /* font-weight: bold; */
    font-size: 18px;
}

/* .trainingStart {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
} */

.trainingStart:hover {
    background-color: #45a049;
}

.adminShow {
    margin-left: 40px;
    color: #35a4f3
}
.document-upload-container {
    display: flex;
    height: 100vh;
    background-color: #181818;
    /* Dark background like the screenshot */
    color: #0c040481;
    /* Light text color */
}

.sidebar h1 {
    margin-bottom: 20px;
    /* color: #f1f1f1; */
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    /* color: #f1f1f1; */
}

.sidebar ul li {
    margin-bottom: 10px;
    cursor: pointer;
    /* color: #bdbdbd; */
    /* Light text color */
}

.chat-doc {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 35%;
    z-index: 500;
}

.chat-anal {
    width: 100%;
    height: calc(100vh - 60px);
    height: calc(var(--vh, 1vh) * 100 - 60px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.chat-anal::-webkit-scrollbar {
    width: 15px;
}

.chat-anal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.chat-anal::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.chat-anal p {
    width: fit-content;
    font-size: 17px;
    padding: 5px 6px;
    color: white;
    border-radius: 30px;
    background-color: rgb(31, 31, 31);
    margin-block: 5px;
    max-width: 400px;
    margin-left: 5px;
    opacity: 10;
}

.chat-anal p.bot {
    margin-block: 2px;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 5px;
}

.chat-anal p.me {
    margin-left: auto;
    margin-right: 10px;
    background-color: #35a4f3;
}

.chat-anal p .time {
    font-size: 10px;
    color: rgb(143, 143, 143);
    text-align: end;
}

.chat-anal {
    height: calc(100vh - 70px);
    height: calc(var(--vh, 1vh) * 100 - 70px);
}

.main-contents {
    width: 56%;
    padding-right: 10%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background-color: #1e1e1e;
    /* Main content area color */
}

.main-contents h1 {
    /* margin-bottom: px; */
    color: #f1f1f1;
    font-size: large;
    color: rgb(30, 196, 82);
}

.response-display {
    background-color: #333333;
    /* Response display background */
    /* padding: 10px; */
    margin-top: 10px;
    border-radius: 5px;
    color: #f1f1f1;
    height: auto;
}

.file-upload-form {
    overflow-y: auto;
    background-color: #333333;
    /* padding-bottom: 15px; */
    border-radius: 15px;
    display: flex;
    justify-content: left;
    margin-bottom: 1%;
    margin-top: 97%;
    z-index: 2;
}

/* .file-input {
    padding: 10px;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 10px;
    width: 10%;
    align-items: left;
    display: none;
} */

.msgbtn {
    background: transparent;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.msgbtn img {
    width: 22px;
    height: 22px;
}

.msgbtn:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

/* .full {
    display: flex;
    height: 100vh;
} */

.form-groups {
    margin-bottom: 15px;
    color: black;
    border-radius: 5%;
    /* Adjust the space as needed */
}

.modal-input {
    width: 100%;
    padding: 10px;
    /* border: 1px solid #f1dfdf8c; */
    border-radius: 10px;
}

.header-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* To give space between logout and language */
    margin-bottom: 10px;
    /* Add space below the controls */
}

.language {
    /* padding: px; */
    border-radius: 5px;
    /* border: 1px solid #ccc; */
}
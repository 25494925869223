.modal-backdrop {
    position: fixed;
    top: 23%;
    left: 40%;
    right: 0;
    /* bottom: 0; */
    background-color: white;
    display: flex;
    max-width: 400px;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

@media screen and (max-width: 768px) {
    .modal-backdrop {
       left: 10px;
       right: 10px;
    }
}

@media screen and (max-width: 480px) {
    .modal-backdrop {
       left: 10px;
       right: 10px;
    }
}

.modal_login {
    background-color: white;
    color: #94a2d6;
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 0.5%;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: red;
    cursor: pointer;
}

.auth-options {
    margin: 20px 0;
}

.auth-button {
    background-color: #4682B4;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
}

.auth-button img {
    margin-right: 10px;
}

.auth-button.google {
    background-color: #4285f4;
}

.auth-button.apple {
    background-color: #000;
}

.email-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
}

.trouble-signing {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
}

.privacy-policy {
    margin-top: 20px;
    font-size: 12px;
    color: #888;
}

.privacy-policy a {
    color: #4682B4;
    text-decoration: underline;
}

.modal-heading{
    color: #4682B4;
    font-weight: w500;
    margin-bottom: 20px;
    font-size: 18px;
}

.modal-form .form-group {
    margin-bottom: 15px;
    color: black;
    /* Adjust the space as needed */
}

.modal-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #5252528c;
    border-radius: 2px;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.language-dropdown {
    /* max-height: 1%; */
    /* Adjust height to show 3-4 options */
    overflow-y: auto;
    /* Enable vertical scroll */
    /* display: block; */
    /* width: 100%; */
    /* Make sure it fits well in the form */
}

.checkbox-group label {
    display: block;
    margin-bottom: 8px;
}

.checkbox-group input {
    margin-right: 8px;
}